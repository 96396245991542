import NetworkContent from '@/helpers/networking/NetworkContent';

export default {
  state: {
    teams: [],
    selectedTeams: [],
    teamSpace: [],
    assessments: [],
    notifications: [],
    overlayNotes: [],
    frontendOverlays: [],
    toastNotifications: [],
    refreshValue: Date.now(),
    profileDashboardDesc: null,
    devTippOverview: [],
    devTippLibrary: [],
    showDevTips: false,
    knowledgeDatabaseContent: [],
    kssHistory: null,
    quickAccessLinks: [],
    personalGoals: [],
    selectedCreatorTab: '',
    maxLimit: 0,
    activeGoalsCount: 0,
    goalUpdateObject: {},
    travelBuddyMeetingInfo: false,
    travelBuddyMeetingStatus: '',
    travelBuddyFeedbackText: '',
    newUserDemoContent: '',
    all360Mindsets: [],
  },
  getters: {
    getTeams: (state) => state.teams,
    getNotifications: (state) => state.notifications,
    getToastNotifications: (state) => state.toastNotifications,
    getOverlayNotes: (state) => state.overlayNotes,
    getFrontendOverlays: (state) => state.frontendOverlays,
    getRefreshValue: (state) => state.refreshValue,
    getProfileDashboardDesc: (state) => state.profileDashboardDesc,
    getDevTippOverview: (state) => state.devTippOverview,
    getDevTippLibrary: (state) => state.devTippLibrary,
    getShowDevTipps: (state) => state.showDevTips,
    getKnowledgeDatabaseContent: (state) => state.knowledgeDatabaseContent,
    getHasNewDevTipps: (state) => state.devTippOverview.filter((el) => el.new === true).length > 0,
    visibleNoteOverlays: (state) => state.overlayNotes.length > 0,
    visibleFrontendOverlays: (state) => state.frontendOverlays.length > 0,
    getKssHistory: (state) => state.kssHistory,
    getQuickAccessLinks: (state) => state.quickAccessLinks,
    getPersonalGoals: (state) => state.personalGoals,
    getSelectedCreatorTab: (state) => state.selectedCreatorTab,
    getPersonalGoalsMaxLimit: (state) => state.maxLimit,
    getActiveGoalsCount: (state) => state.activeGoalsCount,
    getGoalUpdateObject: (state) => state.goalUpdateObject,
    getTravelBuddyMeetingInfo: (state) => state.travelBuddyMeetingInfo,
    getTravelBuddyMeetingStatus: (state) => state.travelBuddyMeetingStatus,
    getTravelBuddyFeedbackText: (state) => state.travelBuddyFeedbackText,
    getNewUserDemoContent: (state) => state.newUserDemoContent,
    get360Mindset: (state) => state.all360Mindsets,
  },
  mutations: {
    setRefreshValue: (state) => {
      state.refreshValue = Date.now();
    },
    setTeams: (state, teams) => {
      state.teams = [];
      if (Array.isArray(teams)) {
        teams.forEach((el) => {
          state.teams.push({
            _id: el._id,
            name: el.name,
            isChecked: false,
          });
        });
      }
    },
    resetTeams: (state) => {
      state.teams = [];
    },
    setSelectedTeams: (state, data) => {
      state.selectedTeams = data;
    },
    toggleTeamSelection: (state, teamId) => {
      const indexToDelete = state.selectedTeams.indexOf(teamId);
      if (indexToDelete > -1) {
        state.selectedTeams.splice(indexToDelete, 1);
      } else {
        state.selectedTeams.push(teamId);
      }
    },
    setProfileDashboardDesc: (state, data) => {
      state.profileDashboardDesc = data;
    },
    setTeamspace: (state, data) => {
      data.forEach((el) => {
        state.teamSpace.push(el);
      });
    },
    setAssessments: (state, data) => {
      state.assessments = data;
    },
    setNotifications: (state, data) => {
      state.notifications = data;
    },
    setToastNotification: (state, data) => {
      state.toastNotifications.push(data);
    },
    setOverlayNotes(state, overlays) {
      state.overlayNotes = overlays;
    },
    setDevTippOverview: (state, data) => {
      state.devTippOverview = data;
    },
    setDevTippLibrary: (state, data) => {
      state.devTippLibrary = data;
    },
    setKnowledgeDatabaseContent: (state, data) => {
      state.knowledgeDatabaseContent = data;
    },
    setOverlayNote(state, data) {
      state.overlayNotes = data;
    },
    addFrontendOverlay(state, overlay) {
      state.frontendOverlays.push(overlay);
    },
    toggleShowDevTipps: (state) => {
      state.showDevTips = !state.showDevTips;
    },
    setShowDevTipps: (state, data) => {
      state.showDevTips = data;
    },
    removeFrontendOverlay(state, id) {
      const index = state.frontendOverlays.findIndex((el) => el.id === id);
      state.frontendOverlays.splice(index, 1);
    },
    removeTeams: (state) => {
      state.teams = [];
    },
    removeSelectedTeams: (state) => {
      state.selectedTeams = [];
    },
    removeTeamspace: (state) => {
      state.teamSpace = [];
    },
    removeAssessments: (state) => {
      state.assessments = [];
    },
    removeToastNotification: (state, id) => {
      const index = state.toastNotifications.findIndex((el) => el.id === id);
      state.toastNotifications.splice(index, 1);
    },
    setKssHistory: (state, data) => {
      state.kssHistory = data;
    },
    setQuickAccessLinks: (state, data) => {
      state.quickAccessLinks = data;
    },
    setPersonalGoals: (state, data) => {
      state.personalGoals = data;
    },
    setSelectedCreateTab: (state, data) => {
      state.selectedCreatorTab = data;
    },
    setPersonalGoalsMaxLimit: (state, data) => {
      state.maxLimit = data;
    },
    setActiveGoalsCount: (state, data) => {
      state.activeGoalsCount = data;
    },
    setGoalUpdateObject: (state, data) => {
      state.goalUpdateObject = data;
    },
    removeGoalsObject: (state) => {
      state.goalUpdateObject = {};
    },
    setTravelBuddyMeetingInfo: (state, data) => {
      state.travelBuddyMeetingInfo = data;
    },
    submitTravelBuddyFeedback: (state, data) => {
      state.travelBuddyFeedback = data;
    },
    setTravelBuddyMeetingStatus: (state, data) => {
      state.travelBuddyMeetingStatus = data;
    },
    setTravelBuddyFeedbackText: (state, data) => {
      state.travelBuddyFeedbackText = data;
    },

    setNewUserDemoContent: (state, data) => {
      state.newUserDemoContent = data;
    },

    set360Mindset: (state, data) => {
      state.all360Mindsets = data;
    },
  },
  actions: {
    async getTeams({ commit }) {
      const { data } = await NetworkContent.getTeams();
      commit('setTeams', data.teams);
    },
    async resetTeams({ commit }) {
      commit('resetTeams');
    },
    async getTeamspace({ commit }) {
      const { data } = await NetworkContent.getTeamspace();
      commit('setTeamspace', data.data);
    },
    async getAssessmentsOverview({ commit }) {
      const { data } = await NetworkContent.getAssessmentsOverview();
      commit('setAssessments', data);
    },
    async getNotifications({ commit }) {
      const { data } = await NetworkContent.getNotifications();
      commit('setNotifications', data.popups);
    },
    async getOverlayNotes({ commit }) {
      const { data } = await NetworkContent.getOverlayNotes();
      commit('setOverlayNotes', data.popups);
    },
    async deactivatePopUp({ commit }, id) {
      await NetworkContent.deactivatePopUp(id);
      const { data } = await NetworkContent.getNotifications();
      commit('setNotifications', data.popups);
    },
    async getDashboardProfileDescription({ commit }, type) {
      const config = {
        params: {
          type,
        },
      };
      const { data } = await NetworkContent.getDashboardProfileDesc(config);
      commit('setProfileDashboardDesc', data.data);
    },
    async deactivateOverlay({ commit }, id) {
      await NetworkContent.deactivateOverlay(id);
      const { data } = await NetworkContent.getOverlayNotes();
      commit('setOverlayNotes', data.popups);
    },
    async setTeams({ dispatch }, {
      id,
      teams,
    }) {
      await NetworkContent.setTeams({ teams });
      dispatch('deactivateOverlay', id);
    },
    async setTags({ dispatch }, {
      id,
      tags,
    }) {
      await NetworkContent.setTags({ tags });
      dispatch('deactivateOverlay', id);
    },
    async getDevTippOverview({ commit }) {
      const { data } = await NetworkContent.getDevTippOverview();
      commit('setDevTippOverview', data.data);
    },
    async newUserDemoComplete({ dispatch }, {
      id,
    }) {
      await NetworkContent.newUserDemoComplete();
      dispatch('deactivateOverlay', id);
    },
    async getDevTippLibrary({ commit }) {
      const { data } = await NetworkContent.getDevTippLibrary();
      commit('setDevTippLibrary', data.data);
    },
    async setNoticeDevTipp({ dispatch }, data) {
      await NetworkContent.setNoticeDevTipp(data);
      dispatch('getDevTippOverview');
    },
    async submitFeedback({ dispatch }, data) {
      await NetworkContent.giveFeedback(data);
      dispatch('getDevTippOverview');
      dispatch('getDevTippLibrary');
    },
    async setTippDone({ dispatch }, data) {
      await NetworkContent.setTippDone(data);
      dispatch('getDevTippOverview');
      dispatch('getDevTippLibrary');
    },
    async getKnowledgeDatabase({ commit }) {
      const { data } = await NetworkContent.getKnowledgeDatabase();
      commit('setKnowledgeDatabaseContent', data.content);
    },
    async getKssHistory({ commit }) {
      const { data } = await NetworkContent.getKssHistory();
      commit('setKssHistory', data.data);
    },
    async getQuickAccessLinks({ commit }) {
      const { data } = await NetworkContent.getQuickAccessLinks();
      commit('setQuickAccessLinks', data.content);
    },
    async getPersonalGoals({ commit }) {
      const { data } = await NetworkContent.getPersonalGoals();
      commit('setPersonalGoals', data.personalGoals);
      commit('setPersonalGoalsMaxLimit', data.maxLimit);
    },
    async changeGoalStatus({ dispatch }, data) {
      await NetworkContent.changeGoalStatus(data);
      dispatch('getPersonalGoals');
    },
    async submitPersonalGoalFeedback({ dispatch }, data) {
      await NetworkContent.submitPersonalGoalFeedback(data);
      dispatch('getPersonalGoals');
    },
    async createPersonalGoal({ dispatch }, data) {
      await NetworkContent.createPersonalGoal(data);
      dispatch('getPersonalGoals');
    },
    async updatePersonalGoal({ dispatch }, data) {
      await NetworkContent.updatePersonalGoal(data);
      dispatch('getPersonalGoals');
    },
    async getTravelBuddyInfo({ commit }, payload) {
      const { data } = await NetworkContent.getTravelBuddyMeetingInfo(payload);
      commit('setTravelBuddyMeetingInfo', data);
      commit('setTravelBuddyMeetingStatus', data.meetingInfo?.meetingStatus ?? '');
      commit('setTravelBuddyFeedbackText', data.myFeedback ?? '');
    },
    async submitTravelBuddyFeedback({ commit }, data) {
      await NetworkContent.submitTravelBuddyFeedback(data);
      commit('submitTravelBuddyFeedback', data);
    },
    async getNewUserDemoContent({ commit }) {
      const { data } = await NetworkContent.getNewUserDemoContent();
      if (data.content.length) {
        const filteredContent = data.content.filter((item) => item.adminOnly === false);
        commit('setNewUserDemoContent', filteredContent);
      }
    },
    async get360Mindset({ commit }) {
      try {
        const { data } = await NetworkContent.get360Mindset();
        commit('set360Mindset', data.all360Mindsets);
      } catch (error) {
        console.error(error);
      }
    },
    async send360MindsetReminder({ dispatch }, data) {
      await NetworkContent.send360MindsetReminder(data);
      dispatch('get360Mindset');
    },
    async extend360Mindset({ dispatch }, data) {
      await NetworkContent.extend360Mindset(data);
      dispatch('get360Mindset');
    },
    async sendUserFeedback(_, data) {
      await NetworkContent.sendUserFeedback(data);
    },
  },
};
