<template>
  <div class="feature-status-overview">
    <div class="selection">
      <div class="selection-from">
        <div class="selection-text">
          <p :class="getTitleClass">{{ $t(`mngFeatureComponent.${feature.name}`) }}</p>
          <div class="list-items" @click.prevent="toggleShowDropdown" ref="listItems">
            <div class="item checked" id="yourself_left">
              <span class="item-text" id="txt_yourself">
                {{ selectedTab }}
              </span>
              <span class="checkbox">
                <i class="fa-solid fa-circle-check check-icon">
                  <font-awesome-icon icon="fa-solid fa-circle-check check-icon"/>
                </i>
              </span>
            </div>
          </div>
        </div>
        <div ref="container" class="selection-container" v-if="showDropDown">
          <ul class="list-items">
            <li
              :class="[
                'item',
                { 'checked': selectedTab === activatedTab }]"
              :key="activatedTab"
              @click.prevent="setSelected(activatedTab)"
            >
              <span :class="'item-text'">{{ activatedTab }}</span>
              <span class="checkbox">
                <i class="fa-solid fa-circle-check check-icon">
                  <font-awesome-icon icon="fa-solid fa-circle-check check-icon"/>
                </i>
              </span>
            </li>
            <li
              :class="[
                'item',
                { 'checked': selectedTab === deactivatedTab }]"
              :key="deactivatedTab"
              @click.prevent="setSelected(deactivatedTab)"
            >
              <span class="item-text">{{ deactivatedTab }}</span>
              <span class="checkbox">
                <i class="fa-solid fa-circle-check check-icon">
                  <font-awesome-icon icon="fa-solid fa-circle-check check-icon"/>
                </i>
              </span>
            </li>
            <li
              :class="[
                'item',
                { 'checked': selectedTab === gatedTab }]"
              :key="gatedTab"
              @click.prevent="setSelected(gatedTab)"
            >
              <span :class="'item-text'">{{ gatedTab }}</span>
              <span class="checkbox">
                <i class="fa-solid fa-circle-check check-icon">
                  <font-awesome-icon icon="fa-solid fa-circle-check check-icon"/>
                </i>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ManagementFeatureRowComponent',
  props: {
    feature: Object,
    upperFeatureName: String,
  },
  data() {
    return {
      showDropDown: false,
      tabs: [
        'Activated',
        'Deactivated',
        'Gated',
      ],
      activatedTab: 'Activated',
      deactivatedTab: 'Deactivated',
      gatedTab: 'Gated',
      selectedTab: '',
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
    getTitleClass() {
      return !this.upperFeatureName && 'title';
    },
  },
  mounted() {
    this.selectedTab = this.changeTab();
  },
  methods: {
    changeTab() {
      if (this.upperFeatureName) {
        switch (this.feature.status) {
          case 1:
            return 'Activated';
          case 0:
            return 'Gated';
          case -1:
            return 'Deactivated';
          default:
            return 'Deactivated';
        }
      } else {
        let status;
        status = this.feature.subFeatures?.map((subFeature) => subFeature.status)[0];
        status = this.feature.subFeatures?.map(
          (subFeature) => subFeature.status === status
        ).includes(false) === false ? status : -2;

        switch (status) {
          case 1:
            return 'Activated';
          case 0:
            return 'Gated';
          case -1:
            return 'Deactivated';
          default:
            return 'None';
        }
      }
    },
    toggleShowDropdown() {
      this.showDropDown = !this.showDropDown;
    },
    isChecked(toCompare) {
      if (toCompare === this.selectedTab) {
        return 'item checked';
      }
      return 'item';
    },
    getCurrentFeatureStatusValue(value) {
      switch (value) {
        case 'Activated':
          return 1;
        case 'Gated':
          return 0;
        case 'Deactivated':
          return -1;
        default:
          return -1;
      }
    },
    setSelected(tab) {
      this.toggleShowDropdown();
      this.selectedTab = tab;
      if (this.upperFeatureName) {
        this.$emit('onChangeFeatureValue', {
          upperFeatureName: this.upperFeatureName,
          subFeatureName: this.feature.name,
          value: this.getCurrentFeatureStatusValue(tab),
        });
      } else {
        this.$emit('onChangeFeatureAllValue', {
          featureName: this.feature.name,
          value: this.getCurrentFeatureStatusValue(tab),
        });
      }
    },
  },
  watch: {
    showDropDown() {
      if (!this.showDropDown) {
        return;
      }

      const clickListener = (e) => {
        let el = e.target;
        if (!el || !el.parentNode) return;

        while (el && el !== this.$refs.listItems) {
          el = el.parentNode;
        }

        if (!el) {
          this.showDropDown = false;
        }
      };
      window.addEventListener('click', clickListener);
    },
    feature() {
      this.selectedTab = this.changeTab();
    }
  }
};
</script>

<style scoped>
.popup-overview {
  font-size: 12px;
  background-color: var(--color-milk);
  padding: 1em;
  border-radius: 2em;
  line-height: 1.5em;
  box-shadow: 4px 5px 0 3px rgba(0, 0, 0, 0.1);
  min-width: 300px;
  max-width: 90%;
  max-height: 90%;

  position: relative;
  overflow-y: auto;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 12px;
  background-color: transparent;
  border: none;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  color: black;
}

.close-btn:hover {
  color: var(--color-orange);
}

.button {
  display: block;
  margin-top: 0.5rem;

  padding: 10px 35px;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
  width: 100%;
}

.delete-button {
  color: var(--color-orange);
  cursor: pointer;
}

.feature-status-overview {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feature-status-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 10px 10px 20px;
}

/*------------------ SELECTION TO -------------------*/
.selection {
  position: relative;
  top: 0;
  right: 0;
}

.selection-text {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  width: 100%;
}

.selection-text p {
  margin-right: 10px;
  font-family: "Poppins";
  font-size: 20px;
}

.box .content .oim h2 {
  font-size: 23px;
  padding: 50px 0 10px;
}

.selection {
  width: 100%;
}

.selection-from {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: flex-end; /* Align items to the right */
  position: relative; /* Add relative positioning to the parent container */
}

.selection-container {
  position: absolute; /* Change to absolute */
  top: 70px;
  right: 0; /* Position to the top right */
  box-sizing: border-box;
  border-radius: 0px 0px 20px 20px;
  cursor: pointer;
  padding-bottom: 8px;
  z-index: 20;
}

.selection-from .list-items {
  display: block;
  max-height: 140px;
  width: 150px;
  overflow-y: scroll;
  position: relative;
}

.selection-from .list-items::-webkit-scrollbar {
  width: 5px;
  background: transparent;
  border-radius: 0 8px 8px 0;
}

.selection-from .list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
  pointer-events: none;
}

.list-items {
  width: 100%;
  max-height: 0;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  margin-bottom: 10px;
  border: 1px solid var(--color-light-grey);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background-color: var(--color-milk);
  overflow: hidden;
  z-index: 0;
  position: relative;
}

.list-items .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  cursor: pointer;
  transition: 0.3s;
  z-index: 99;
  position: relative;
}

.list-items .item:hover {
  transform: scale(1.02);
}

.list-items .item .item-text {
  display: flex;
  align-items: center;
}

.list-items .item .item-text img {
  width: 2rem;
  margin-right: 12px;
}

.list-items .item .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 1px solid var(--color-dark);
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.checkbox .check-icon {
  color: var(--color-orange);
  font-size: 24px;
  z-index: 100;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

/*".item" auf "checked"-Klasse setzten, wenn angeklickt. Dann erscheint Häckchen.*/
.item.checked .check-icon {
  transform: scale(1);
}

.selection .selection-from .title {
  font-size: 24px;
}

@media only screen and (max-width: 650px) {
  .selection-text p {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }
}
</style>
