<template>
  <breadcrumbs-component :bread-crumbs="oimIntroBreadCrumbs"/>
  <div class="contentContainer" v-if="getOimIntroEnabled">
    <div class="intro">
      <div class="intro-headline">
        <h1 id="txt_headline">
          {{ $t('OimIntroComponent.headline') }}
        </h1>
      </div>
      <div class="intro-content">
        <p id="txt_introContent">
          {{ $t('OimIntroComponent.introContent1') }}
          <br/>
          {{ $t('OimIntroComponent.introContent2') }}
          <br/><br/>
        </p>
        <p v-html="$t('OimIntroComponent.introContent3')" id="txt_introContent2">
        </p>
        <button type="button" name="start-oim" id="btn_oim" class="btns"
                @click.prevent="navigateToQuestionnaire">
                {{ $t('OimIntroComponent.letsStart') }}
        </button>
      </div>
    </div>
    <div class="how-it-works" id="div_howItWorks">
      <h2>{{ $t('OimIntroComponent.howItWorks') }}
      </h2>
      <ul>
        <li>{{ $t('OimIntroComponent.howItWorks1') }}</li>
        <li>{{ $t('OimIntroComponent.howItWorks2') }}</li>
        <li>{{ $t('OimIntroComponent.howItWorks3') }}
          <i>{{ $t('OimIntroComponent.disagree') }}</i>
          {{ $t('OimIntroComponent.howItWorks3a') }}<i>
            {{ $t('OimIntroComponent.agree') }}</i></li>
        <li>{{ $t('OimIntroComponent.howItWorks4') }}</li>
        <li>{{ $t('OimIntroComponent.howItWorks5') }}</li>
      </ul>
    </div>
    <!---------------- OIM DEFINITION ------------------->
    <div class="definition" id="div_definition">
      <h4>
        <span style="float:left;"> {{ $t('OimIntroComponent.definitionHeading') }}</span>
      </h4><br>
      {{ $t('OimIntroComponent.definition1') }}
      <i> {{ $t('OimIntroComponent.definition2') }} </i><br>
      (Engelsberger et al, 2022)
    </div>
    <frontend-gated-component value="oimIntro" />
  </div>
  <provide-feedback-component></provide-feedback-component>
</template>

<script>
import BreadcrumbsComponent from '@/components/Home/Shared/BreadcrumbsComponent.vue';
import ProvideFeedbackComponent from '@/components/global/ProvideFeedbackComponent.vue';
import FrontendGatedComponent from '../Shared/FrontendGated/FrontendGatedComponent.vue';
import FeatureHelper from '@/helpers/FeatureHelper';

export default {
  name: 'OimIntroComponent.vue',
  props: ['id'],
  components: { BreadcrumbsComponent, ProvideFeedbackComponent, FrontendGatedComponent },
  data() {
    return {
      oimIntroBreadCrumbs: [
        {
          goTo: '/',
          isActive: false,
          name: 'HOME',
        },
        {
          goTo: this.$route.fullPath,
          isActive: true,
          name: this.$t('BreadCrumbs.Module2OIM'),
        },
      ],
    };
  },
  methods: {
    navigateToQuestionnaire() {
      this.$router.push(`/evaluation/questionnaire?id=${this.id}`);
    },
    featureStatus(name) {
      return FeatureHelper.featureStatus(name);
    }
  },
  computed: {
    getOimIntroEnabled() {
      return this.featureStatus('oimIntro') !== -1;
    }
  }
};
</script>

<style scoped>

.contentContainer {
  position: relative;
  margin: 0 auto;
  width: 80%;
  height: 100%;
}

.contentContainer .intro {
  display: flex;
  flex-wrap: wrap;
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  background-color: var(--color-light);
}

.contentContainer .intro > * {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 280px;
}

.intro-headline {
  padding: 0.5rem;
}

.intro-content {
  padding: 0.5rem;
}

.contentContainer .how-it-works {
  display: block;
  margin: 5rem 1rem;
}

.how-it-works p {
  padding-top: 1rem;
}

.how-it-works li {
  padding-top: 1rem;
  margin-left: 1rem;
  list-style: disc;
}

.definition {
    margin: 5rem 1rem;
}

.btns {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  padding: 10px 35px;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

button[type=button]:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

/*==================== MEDIA QUERIES =====================*/
@media screen and (max-width: 500px) {
  .contentContainer {
    height: 100%;
    width: 100%;
    border-radius: 0;
  }
}

@media (max-width: 768px) {
  .breadcrumbs {
    margin: -2rem 0.5rem;
  }

  .contentContainer .intro {
    margin-top: 3rem;
  }
}

</style>
