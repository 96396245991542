import store from '@/store';

import FeatureHelper from '@/helpers/FeatureHelper';

// eslint-disable-next-line import/prefer-default-export
export async function checkIfFeatureAllow(route, next) {
  try {
    if (store.state.feature.features) {
      if (store.state.auth.user.permissionsFlags.admin) {
        const pageFeatures = store.getters.getFeatures?.filter(
          (featureItem) => featureItem.route === route,
        )[0];
        if (!pageFeatures) next();

        const adminSubFeatures = pageFeatures.subFeatures?.map(
          (subFeature) => ({ ...subFeature, status: 1 })
        );
        const adminPageFeatures = { ...pageFeatures, subFeatures: adminSubFeatures };
        store.commit('setPageFeatures', adminPageFeatures ?? []);
      } else {
        const isAllDeactivated = FeatureHelper.IsAllSubFeatureDeactivated(route);
        if (isAllDeactivated) {
          next('/');
        } else FeatureHelper.pageFeatures(route);
      }
    }
    next();
  } catch (error) {
    next('/');
  }
}
