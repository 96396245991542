<template>
  <div class="popup-overview">
    <div class="popup-content">
      <div class="popup-content">
        <h1> {{ $t('TaskCreatorOverlayComponent.createEvent') }}</h1>
        <div class="selection">
          <div class="selection-from">
            <div class="selection-text">
              <p>{{ $t('TaskCreatorOverlayComponent.chooseEvent') }}</p>
              <div class="list-items" @click.prevent="toggleShowDropdown">
                <div class="item checked" id="yourself_left">
                    <span class="item-text" id="txt_yourself">
                      {{ this.selectedTab }}
                    </span>
                  <span class="checkbox">
                      <i class="fa-solid fa-circle-check check-icon">
                        <font-awesome-icon icon="fa-solid fa-circle-check check-icon"/>
                      </i>
                    </span>
                </div>
              </div>
            </div>
            <div v-show="show360Note" class="note le"
                 :class="{ 'visible': show360Note }"
                 :style="{'top': get360NotesTopValue}">
                    <span id="txt_dataMissing">
                      {{ $t('TaskCreatorOverlayComponent.alreadyRequested') }}
                    </span>
            </div>
            <div v-show="showGoalsNote" class="goal-note le"
                :class="{
                  'visible': showGoalsNote,
                  'gated': this.getGatedStatus('personalGoals') === 'gated'
                }"
                 :style="{'top': getGoalNotesTopValue}">
                    <span id="txt_dataMissing">
                      {{ this.getGatedStatus('personalGoals') === 'gated'
                        ? $t('GateComponent.gateText')
                        : $t('TaskCreatorOverlayComponent.personalGoalsError') }}
                    </span>
            </div>
            <div v-show="showNote" class="goal-note le"
                 :class="{ 'visible gated': this.getGatedStatus('kssTracker') === 'gated' }"
                 :style="{'top': getKSSTopValue}">
                    <span id="txt_dataMissing">
                      {{ $t('GateComponent.gateText') }}
                    </span>
            </div>
            <div class="selection-container" v-if="showDropDown">
              <ul class="list-items">
                <li
                  :class="[
                    'item',
                   { 'disabled': getDisabledTab(kssTrackerTab) },
                   { 'checked': selectedTab === kssTrackerTab }]"
                  v-if="isTabAvailable(kssTrackerTab)"
                  :key="kssTrackerTab"
                  @click.prevent="setSelected(kssTrackerTab)"
                  @mouseenter="showNote = getDisabledTab(kssTrackerTab)"
                  @mouseleave="showNote = false"
                  @focusin="showNote = getDisabledTab(kssTrackerTab)"
                  @focusout="showNote = false">
                  <span :class="['item-text', { 'disabled': getDisabledTab(kssTrackerTab) }]">
                    {{ kssTrackerTab }}</span>
                  <span class="checkbox">
                    <i class="fa-solid fa-circle-check check-icon">
                      <font-awesome-icon icon="fa-solid fa-circle-check check-icon"/>
                    </i>
                  </span>
                </li>
                <li
                  :class="[
                    'item',
                   { 'disabled': getDisabledTab(mindset360Tab) },
                   { 'checked': selectedTab === mindset360Tab }]"
                  v-if="isTabAvailable(mindset360Tab)"
                  :key="mindset360Tab"
                  @click.prevent="setSelected(mindset360Tab)"
                  @mouseenter="show360Note = getDisabledTab(mindset360Tab)"
                  @mouseleave="show360Note = false"
                  @focusin="show360Note = getDisabledTab(mindset360Tab)"
                  @focusout="show360Note = false">
                  <span :class="['item-text', { 'disabled': getDisabledTab(mindset360Tab) }]">
                    {{ mindset360Tab }}</span>
                  <span class="checkbox">
                    <i class="fa-solid fa-circle-check check-icon">
                      <font-awesome-icon icon="fa-solid fa-circle-check check-icon"/>
                    </i>
                  </span>
                </li>
                <li
                  :class="[
                    'item',
                   { 'disabled': getDisabledTab(goalsTab) },
                   { 'checked': selectedTab === goalsTab }]"
                  v-if="isTabAvailable(goalsTab)"
                  :key="goalsTab"
                  @click.prevent="setSelected(goalsTab)"
                  @mouseenter="showGoalsNote = getDisabledTab(goalsTab)"
                  @mouseleave="showGoalsNote = false"
                  @focusin="showGoalsNote = getDisabledTab(goalsTab)"
                  @focusout="showGoalsNote = false">
                  <span :class="['item-text', { 'disabled': getDisabledTab(goalsTab) }]">
                    {{ goalsTab }}</span>
                  <span class="checkbox">
                    <i class="fa-solid fa-circle-check check-icon">
                      <font-awesome-icon icon="fa-solid fa-circle-check check-icon"/>
                    </i>
                  </span>
                </li>
                <li
                  :class="[
                    'item',
                   { 'disabled': getDisabledTab(workshopTab) },
                   { 'checked': selectedTab === workshopTab }]"
                  v-if="isTabAvailable(workshopTab)"
                  :key="workshopTab"
                  @click.prevent="setSelected(workshopTab)"
                  @mouseenter="showGoalsNote = getDisabledTab(workshopTab)"
                  @mouseleave="showGoalsNote = false"
                  @focusin="showGoalsNote = getDisabledTab(workshopTab)"
                  @focusout="showGoalsNote = false">
                  <span :class="['item-text', { 'disabled': getDisabledTab(workshopTab) }]">
                    {{ workshopTab }}</span>
                  <span class="checkbox">
                    <i class="fa-solid fa-circle-check check-icon">
                      <font-awesome-icon icon="fa-solid fa-circle-check check-icon"/>
                    </i>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="creator">
        <kss-creator-component
          v-if="selectedTab === $t('TaskCreatorOverlayComponent.kssTracker')"
          :data="data"
          />
        <frontend-gated-component
          v-if="selectedTab === $t('TaskCreatorOverlayComponent.kssTracker')"
          :should-gate="getDisabledTab(kssTrackerTab)"
        />
        <mindset360-creator-component
          v-if="selectedTab === $t('TaskCreatorOverlayComponent.mindset360')"
          :data="data"/>
        <frontend-gated-component
          v-if="selectedTab === $t('TaskCreatorOverlayComponent.mindset360')"
          :should-gate="getDisabledTab(mindset360Tab)"
        />
        <personal-goals-form-component
          v-if="selectedTab === $t('TaskCreatorOverlayComponent.personalGoals')"
          :data="data"/>
        <frontend-gated-component
          v-if="selectedTab === $t('TaskCreatorOverlayComponent.personalGoals')"
          :should-gate="getDisabledTab(goalsTab)"
        />
        <workshop-form-component
          v-if="selectedTab === $t('TaskCreatorOverlayComponent.workshop')"
          @submit="workshopSubmit"
          :data="data"/>
        <frontend-gated-component
          v-if="selectedTab === $t('TaskCreatorOverlayComponent.workshop')"
          :should-gate="getDisabledTab(workshopTab)"
        />
      </div>
    </div>
    <button class="close-btn" @click.prevent="closePopup">
      <font-awesome-icon icon="fa-xmark"/>
    </button>
  </div>
</template>

<script>
import KssCreatorComponent from '@/components/Home/KssCreator/KssCreatorComponent.vue';
import Mindset360CreatorComponent
  from '@/components/Home/Mindset360Creator/Mindset360CreatorComponent.vue';
import PersonalGoalsFormComponent from '@/components/Home/PersonalGoalsComponent/PersonalGoalsFormComponent.vue';
import WorkshopFormComponent from '@/components/Home/WorkshopComponent/WorkshopFormComponent.vue';
import FrontendGatedComponent from '../FrontendGated/FrontendGatedComponent.vue';
import FeatureHelper from '@/helpers/FeatureHelper';
import FeatureRoute from '@/utils/features';

export default {
  name: 'TaskCreatorOverlayComponent',
  components: {
    Mindset360CreatorComponent,
    KssCreatorComponent,
    PersonalGoalsFormComponent,
    WorkshopFormComponent,
    FrontendGatedComponent,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      mindsetCreationAllowed: true,
      selectedTab: '',
      showDropDown: false,
      tabs: [
        this.$t('TaskCreatorOverlayComponent.kssTracker'),
        this.$t('TaskCreatorOverlayComponent.mindset360'),
        this.$t('TaskCreatorOverlayComponent.personalGoals'),
        this.$t('TaskCreatorOverlayComponent.workshop'),
      ],
      showNote: false,
      show360Note: false,
      showGoalsNote: false,
      kssTrackerTab: this.$t('TaskCreatorOverlayComponent.kssTracker'),
      mindset360Tab: this.$t('TaskCreatorOverlayComponent.mindset360'),
      goalsTab: this.$t('TaskCreatorOverlayComponent.personalGoals'),
      workshopTab: this.$t('TaskCreatorOverlayComponent.workshop'),
    };
  },
  computed: {
    getLastMindsetDate() {
      return this.$store.state.auth.user.lastMindset360;
    },
    getKSSEnabled() {
      return (
        this.getPermissions.admin
        || !FeatureHelper.IsAllSubFeatureDeactivated(FeatureRoute.kssTracker)
      );
    },
    getWorkshopEnabled() {
      return (
        this.getPermissions.admin
        || !FeatureHelper.IsAllSubFeatureDeactivated(FeatureRoute.workshops)
      );
    },
    getPersonalGoalEnabled() {
      return (
        this.getPermissions.admin
        || !FeatureHelper.IsAllSubFeatureDeactivated(FeatureRoute.personalGoals)
      );
    },
    getMindset360Enabled() {
      return (
        this.$store.state.auth.user.mindset360Enabled
        && (
          this.getPermissions.admin
          || !FeatureHelper.IsAllSubFeatureDeactivated(FeatureRoute['360mindSet'])
        )
      );
    },
    getTabs() {
      // filter out if the mindset 360 is disabled for the user
      const tablist = this.tabs.filter((tab) => !(
        (tab === this.$t('TaskCreatorOverlayComponent.mindset360') && !this.getMindset360Enabled)
        || (tab === this.$t('TaskCreatorOverlayComponent.kssTracker') && !this.getKSSEnabled)
        || (tab === this.$t('TaskCreatorOverlayComponent.workshop') && !this.getWorkshopEnabled)
        || (tab === this.$t('TaskCreatorOverlayComponent.personalGoals') && !this.getPersonalGoalEnabled)
      ));

      return tablist;
    },
    getKSSTopValue() {
      if (window.matchMedia('(max-width : 800px)').matches) {
        return '0px';
      }
      return '-30px';
    },
    get360NotesTopValue() {
      if (window.matchMedia('(max-width : 800px)').matches) {
        return this.getKSSEnabled ? '60px' : '0px';
      }
      return this.getKSSEnabled ? '10px' : '-30px';
    },
    getGoalNotesTopValue() {
      if (window.matchMedia('(max-width : 800px)').matches) {
        if (this.getKSSEnabled) {
          return this.getMindset360Enabled ? '90px' : '50px';
        }
        return this.getMindset360Enabled ? '50px' : '0px';
      }
      if (this.getKSSEnabled) {
        return this.getMindset360Enabled ? '50px' : '10px';
      }
      return this.getMindset360Enabled ? '10px' : '-30px';
    },
    getPermissions() {
      return this.$store.state.auth.user.permissionsFlags;
    },
  },
  created() {
    this.selectedTab = this.getSelectedTab();
  },
  methods: {
    getGatedStatus(value) {
      return FeatureHelper.getGatedStatus(value);
    },
    workshopSubmit(params) {
      try {
        this.$store.dispatch('createWorkshop', params);
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponent.workshop.workshopCreated'),
          type: 'success',
        });
        this.closePopup();
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
    async closePopup() {
      this.$store.commit('removeFrontendOverlay', this.data.id);
    },
    isChecked(toCompare) {
      if (toCompare === this.selectedTab) {
        return 'item checked';
      }
      return 'item';
    },
    toggleShowDropdown() {
      this.showDropDown = !this.showDropDown;
    },
    setSelected(tab) {
      if (this.getDisabledTab(tab)) {
        return;
      }
      this.toggleShowDropdown();
      this.selectedTab = tab;
    },
    getDisabledTab(tab) {
      if (tab === this.$t('TaskCreatorOverlayComponent.mindset360')) {
        const date = new Date(this.getLastMindsetDate);
        const currentDate = new Date();
        const sixMonthsAgo = new Date(currentDate);
        sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
        return date > sixMonthsAgo || this.getGatedStatus('360mindSet') === 'gated';
      }
      if (tab === this.$t('TaskCreatorOverlayComponent.personalGoals')) {
        return this.$store.getters.getActiveGoalsCount
         >= this.$store.getters.getPersonalGoalsMaxLimit || this.getGatedStatus('personalGoals') === 'gated';
      }
      if (tab === this.$t('TaskCreatorOverlayComponent.kssTracker')) {
        return this.getGatedStatus('kssTracker') === 'gated';
      }

      if (tab === this.$t('TaskCreatorOverlayComponent.workshop')) {
        return this.getGatedStatus('workshops') === 'gated';
      }

      return false;
    },
    getSelectedTab() {
      let tab = this.$t('TaskCreatorOverlayComponent.workshop');

      if (this.getKSSEnabled) tab = this.$t('TaskCreatorOverlayComponent.kssTracker');
      else if (this.getPersonalGoalEnabled) tab = this.$t('TaskCreatorOverlayComponent.personalGoals');
      else if (this.getMindset360Enabled) tab = this.$t('TaskCreatorOverlayComponent.mindset360');

      this.$store.commit('setSelectedCreateTab', tab);
      return tab;
    },
    isTabAvailable(tab) {
      return this.getTabs.includes(tab);
    },
  },
};
</script>

<style scoped>

h1 {
  font-family: "Poppins";
  font-size: 30px;
  text-align: center;
  font-weight: normal;
  letter-spacing: 0.19px;
  margin: 30px 50px 10px 50px;
  line-height: 32px;
  color: var(--color-dark);
  padding: var(--card-padding) 0 var(--card-padding);
}

.popup-overview {
  font-size: 14px;
  background-color: rgb(251, 251, 251);
  padding: 1em;
  border-radius: 2em;
  line-height: 1.5em;
  box-shadow: 4px 5px 0 3px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 960px;
  height: fit-content;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.popup-content {
  position: relative;
}

.close-btn {
  font-family: 'Poppins';
  position: absolute;
  top: 5px;
  right: 15px;
  background: none;
  border: none;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.selection {
  position: relative;
  top: 0;
  right: 0;
}

.close-btn:hover {
  color: var(--color-orange);
}

.popup-overview h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.creator {
  position: relative;
  width: auto;
  padding-right: 50px;
}

.selection-text {
  display: flex;
  align-items: center;
  position: relative;
}

.selection-text p {
  white-space: nowrap;
  margin-right: 10px;
  font-family: "Poppins";
  font-size: 20px;
}

/*------------------ SELECTION TO -------------------*/
.box .content .oim h2 {
  font-size: 23px;
  padding: 50px 0 10px;
}

.selection {
  display: flex;
  justify-content: flex-end;
}

.selection-from {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: flex-end; /* Align items to the right */
  max-width: 300px;
  margin-right: 50px;
  position: relative; /* Add relative positioning to the parent container */
}

.selection-container {
  position: absolute; /* Change to absolute */
  top: 70px;
  right: 0; /* Position to the top right */
  width: 100%;
  box-sizing: border-box;
  border-radius: 0px 0px 20px 20px;
  cursor: pointer;
  padding-bottom: 8px;
  z-index: 20;
}

.selection-from .list-items {
  display: block;
  max-height: 140px;
  min-width: 250px;
  overflow-y: scroll;
  position: relative;
}

.selection-from .list-items::-webkit-scrollbar {
  width: 5px;
  background: transparent;
  border-radius: 0 8px 8px 0;
}

.selection-from .list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
  pointer-events: none;
}

.list-items {
  width: 100%;
  max-height: 0;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  margin-bottom: 10px;
  border: 1px solid var(--color-light-grey);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background-color: var(--color-milk);
  overflow: hidden;
  z-index: 10;
  position: relative;
}

.list-items .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  cursor: pointer;
  transition: 0.3s;
  z-index: 99;
  position: relative;
}

.list-items .item:hover {
  transform: scale(1.02);
}

.list-items .item .item-text {
  display: flex;
  align-items: center;
}

.list-items .item .item-text img {
  width: 2rem;
  margin-right: 12px;
}

.list-items .item .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 1px solid var(--color-dark);
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.checkbox .check-icon {
  color: var(--color-orange);
  font-size: 24px;
  z-index: 100;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

/*".item" auf "checked"-Klasse setzten, wenn angeklickt. Dann erscheint Häckchen.*/
.item.checked .check-icon {
  transform: scale(1);
}

/*------------------ DISABLED -------------------*/
/*".yourself-compare" auf "disabled"-Klasse gesetzt,
 dann ist yourself nicht auswählbar für Vergleich*/
.selection-from .yourself-compare.disabled > .list-items {
  color: var(--color-disabled);
  pointer-events: none;
}

.selection-from .yourself-compare.disabled > .list-items .checkbox {
  border: 1px solid var(--color-disabled);
  background-color: var(--color-disabled);
}

.selection-from .yourself-compare.disabled > .list-items .item:hover {
  transform: none;
  cursor: auto;
  pointer-events: none;
}

/*------------------ POPUP: NOTE -------------------*/
.note {
  z-index: 10000;
  position: absolute;
  width: 270px;
  padding: 10px;
  margin-bottom: 7px;
  border-radius: 10px;
  color: white;
  background-color: var(--color-error);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
  pointer-events: none;
  text-align: center;
  transform: translate(0, 70%);
  transition: opacity 0.3s, transform 0.3s;
}

.note.visible {
  z-index: 10000; /* Set a high z-index value to ensure it's on top of all elements */
  opacity: 1;
  pointer-events: auto;
  background-color: var(--color-error);
}

.note.re {
  right: 5px;
}

.note.re {
  left: 5px;
}

.note {
  opacity: 1;
}

/*kleines Viereck am unteren Ende des Kästchens*/
.note::before {
  z-index: 10000; /* Set a high z-index for the triangle as well */
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background-color: var(--color-error);
  bottom: -8px;
  left: 25%;
  transform: translateX(-50%) rotate(45deg);
}

/*------------------ POPUP: goals NOTE -------------------*/
.goal-note {
  z-index: 10000;
  position: absolute;
  width: 270px;
  padding: 10px;
  margin-bottom: 7px;
  border-radius: 10px;
  color: white;
  background-color: var(--color-error);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
  pointer-events: none;
  text-align: center;
  transform: translate(0, 70%);
  transition: opacity 0.3s, transform 0.3s;
}

.goal-note.visible {
  z-index: 10000; /* Set a high z-index value to ensure it's on top of all elements */
  opacity: 1;
  pointer-events: auto;
  background-color: var(--color-error);
}

.goal-note.gated {
  background-color: var(--color-gate);
}

.goal-note.re {
  right: 5px;
}

.goal-note.re {
  left: 5px;
  bottom: 5px;
}

.goal-note {
  opacity: 1;
}

/*kleines Viereck am unteren Ende des Kästchens*/
.goal-note::before {
  z-index: 10000; /* Set a high z-index for the triangle as well */
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background-color: var(--color-error);
  bottom: -8px;
  left: 25%;
  transform: translateX(-50%) rotate(45deg);
}

.goal-note.gated::before {
  background-color: var(--color-gate);
}

.list-items .item-text.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.list-items .item.disabled:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 1150px) {
  .popup-overview {
    width: 90%;
    max-width: 1150px;
  }

  .creator {
    padding: 0 50px;
  }
}

@media screen and (max-width: 800px) {
  h1 {
    text-align: center;
    margin: 30px 0 10px 0;
  }

  .selection {
    justify-content: center;
    position: relative;
  }

  .selection-text {
    flex-direction: column;
    align-items: center;
  }

  .selection-text p {
    margin-bottom: 10px;
  }

  .selection-from {
    margin: 0 auto;
  }

  .selection-container {
    margin: 0 auto;
    top: 110px;
  }

  .button-grid {
    margin-right: 5em;
  }
}
</style>
