<template>
  <breadcrumbs-component :breadCrumbs="knowledgeDbBreadCrumbs"/>
  <div
    v-if="getKnowledgeDatabaseEnabled"
    class="knowledge-container"
  >
    <div class="knowledge-content">
      <div class="infotext">
        <knowledge-database-item-component v-for="item in getKnowledgeDatabase"
                                           :title="item.title[getLocale]"
                                           :content="item.pageContent[getLocale]" :key="item"/>
        <frontend-gated-component value="knowledgeDatabase" />
      </div>
    </div>
  </div>
  <provide-feedback-component></provide-feedback-component>
</template>

<script>
import BreadcrumbsComponent from '@/components/Home/Shared/BreadcrumbsComponent.vue';
import KnowledgeDatabaseItemComponent
  from '@/components/Home/KnowledgeDatabaseComponent/KnowledgeDatabaseItemComponent.vue';
import ProvideFeedbackComponent from '@/components/global/ProvideFeedbackComponent.vue';
import FrontendGatedComponent from '../Shared/FrontendGated/FrontendGatedComponent.vue';
import FeatureHelper from '@/helpers/FeatureHelper';

export default {
  name: 'KnowledgeDatabaseComponent',
  components: {
    KnowledgeDatabaseItemComponent,
    BreadcrumbsComponent,
    ProvideFeedbackComponent,
    FrontendGatedComponent,
  },
  data() {
    return {
      knowledgeDbBreadCrumbs: [
        {
          goTo: '/',
          isActive: false,
          name: 'HOME',
        },
        {
          goTo: '/knowledge-database',
          isActive: false,
          name: this.$t('BreadCrumbs.knowledgeDatabase'),
        },
        {
          goTo: '/knowledge-database',
          isActive: true,
          name: this.$t('BreadCrumbs.omindset'),
        },
      ],
      isOMINDExpanded: false,
      isHUGOExpanded: false,
      isRequirementsExpanded: false,
    };
  },
  computed: {
    getKnowledgeDatabase() {
      return this.$store.getters.getKnowledgeDatabaseContent;
    },
    getLocale() {
      return this.$i18n.locale;
    },
    getKnowledgeDatabaseEnabled() {
      return this.featureStatus('knowledgeDatabase') !== -1;
    }
  },
  methods: {
    getIcon(value) {
      return value ? 'fa-solid fa-angle-up' : 'fa-solid fa-angle-down';
    },
    featureStatus(name) {
      return FeatureHelper.featureStatus(name);
    },
  },
  async mounted() {
    await this.$store.dispatch('getKnowledgeDatabase');
  },
  unmounted() {
    this.$store.commit('setKnowledgeDatabaseContent', []);
  },
};
</script>

<style scoped>
/*------------------ CONTENT -------------------*/
.knowledge-container {
  margin: 0 auto;
  position: relative;
}

.knowledge-content {
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 10px;
  width: 100vw;
  max-width: 960px;
  margin: 30px auto;
}

.infotext {
  position: relative;
  display: block;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 30px;
  background: var(--color-light);
}

h3 {
  padding-top: 9px;
}
</style>
