<template>
  <div
    ref="gated"
    :class="getGatedStatus"
    @mouseover="onMouseOver"
    @mouseout="showGatedNote = false"
    @focusin="onMouseOver"
    @focusout="showGatedNote = false"
  >
    <div v-show="showGatedNote" class="note" ref="note"
      :class="{ 'visible': showGatedNote }"
    >
      <a href="https://meetings-eu1.hubspot.com/aurelia-engelsberger" class="text" ref="text">
        {{ $t('GateComponent.gateText') }}
      </a>
    </div>
  </div>
</template>
<script>
import FeatureHelper from '@/helpers/FeatureHelper';

export default {
  name: 'FrontendGatedComponent',
  props: {
    value: String,
    shouldGate: Boolean,
  },
  data() {
    return {
      showGatedNote: false,
    };
  },
  computed: {
    getGatedStatus() {
      if (this.shouldGate) return 'gated';
      return FeatureHelper.getGatedStatusByPage(this.value);
    },
  },
  methods: {
    debounce(func, delay) {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    adjustNotesContainerHeight() {
      const GatedContainer = this.$refs.gated;
      const NotesContainer = this.$refs.note;
      const TextContainer = this.$refs.text;
      const resize = () => {
        if (GatedContainer.clientHeight <= 140) {
          NotesContainer.style.left = '15%';
          NotesContainer.style.top = '-50%';
        } else {
          TextContainer.style.fontSize = '12px';
          NotesContainer.style.paddingTop = '10px';
          NotesContainer.style.paddingBottom = '10px';
          NotesContainer.style.left = '35%';
          NotesContainer.style.top = '5%';
        }
      };
      resize();
    },
    onMouseOver() {
      this.showGatedNote = true;
      this.adjustNotesContainerHeight();
    }
  },
  mounted() {
    this.adjustNotesContainerHeight();
  }
};
</script>
<style scoped>
  .gated {
    position: absolute;
    z-index: 15;
    inset: 0;
    backdrop-filter: blur(5px);
    cursor: pointer;
    border-radius: 1rem;
  }

  .d-none {
    display: none;
  }

  .note::before {
    z-index: 10000; /* Set a high z-index for the triangle as well */
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    background-color: var(--color-gate);
    bottom: -8px;
    left: 10%;
    transform: translateX(-50%) rotate(45deg);
  }

  .note {
    z-index: 10000;
    position: absolute;
    width: 150px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 7px;
    border-radius: 10px;
    color: white;
    background-color: var(--color-gate);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s, transform 0.3s;
    left: 35%;
    top: 5%;
  }

  .note.visible {
    z-index: 10000; /* Set a high z-index value to ensure it's on top of all elements */
    opacity: 1;
    pointer-events: auto;
    background-color: var(--color-gate);
    display: block !important;;
  }

  .text {
    color: white;
    font-size: 12px;
  }
</style>
