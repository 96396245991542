<template>
  <breadcrumbs-component :breadCrumbs="breadCrumbs" />
  <div class="mng-dashboard">
    <WeeklyReport
      v-if="getYourWeeklyReportEnabled"
      :subFeature="pageFeature('yourWeeklyReport')"
    />
    <SatisfactionReport
      v-if="getEmployeeSatisfactionOverTimeEnabled"
      :subFeature="pageFeature('employeeSatisfactionOverTime')"
    />
    <CollaborationTrend
      v-if="getCollaborationTrendOverTimeEnabled"
      :subFeature="pageFeature('collaborationTrendOverTime')"
    />
    <Omindset
      v-if="getTheOMindsetInYourCompanyEnabled"
      :subFeature="pageFeature('theOMindsetInYourCompany')"
    />
  </div>
  <ProvideFeedbackComponent></ProvideFeedbackComponent>
</template>

<script>
import BreadcrumbsComponent from '@/components/Home/Shared/BreadcrumbsComponent.vue';
import WeeklyReport from './WeeklyReportComponent/WeeklyReportComponent.vue';
import SatisfactionReport from './SatisfactionReportComponent/SatisfactionReportComponent.vue';
import CollaborationTrend from './CollaborationTrendComponent/CollaborationTrendComponent.vue';
import Omindset from './OmindsetComponent/OmindsetComponent.vue';
import ProvideFeedbackComponent from '@/components/global/ProvideFeedbackComponent.vue';

export default {
  name: 'ManagementDashboardComponent',
  components: {
    BreadcrumbsComponent,
    WeeklyReport,
    SatisfactionReport,
    CollaborationTrend,
    Omindset,
    ProvideFeedbackComponent,
  },
  data() {
    return {
      breadCrumbs: [
        {
          goTo: '/',
          isActive: false,
          name: this.$t('BreadCrumbs.home'),
        },
        {
          goTo: '/management-dashboard',
          isActive: true,
          name: this.$t('BreadCrumbs.mngDashboard'),
        },
      ],
    };
  },
  computed: {
  },
  methods: {
    pageFeature(name) {
      return this.$store.getters.getPageFeatures?.subFeatures?.filter(
        (subfeature) => subfeature.name === name,
      )[0];
    },
    getYourWeeklyReportEnabled() {
      return this.pageFeature('yourWeeklyReport').status !== -1;
    },
    getEmployeeSatisfactionOverTimeEnabled() {
      return this.pageFeature('employeeSatisfactionOverTime').status !== -1;
    },
    getCollaborationTrendOverTimeEnabled() {
      return this.pageFeature('collaborationTrendOverTime').status !== -1;
    },
    getTheOMindsetInYourCompanyEnabled() {
      return this.pageFeature('theOMindsetInYourCompany').status !== -1;
    },

  },
  mounted() {
  },
};
</script>

<style scoped>
@media (min-width: 1920px) {
  .breadcrumbs {
    margin: 0 5px !important;
  }
}
</style>
